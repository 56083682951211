
import Axios from '../libs/AxiosPlugin'

// 接口地址
//const BASE_PATH = 'http://localhost:8083'
//const BASE_PATH = 'http://a2.ttifx.com'
const BASE_PATH = 'https://qcapi.kulf.cn'

//获取授权url
export const wxloginurl = params => { return Axios.get(`${BASE_PATH}/api/user/wxloginurl`, params).then(res => res.data); };
// 用户登录
export const login = params => { return Axios.post(`${BASE_PATH}/api/user/login`, params).then(res => res.data); };
//获取幻灯片
export const banners = params => { return Axios.get(`${BASE_PATH}/api/course/getbanners`, params).then(res => res.data); };
//课程列表
export const courseList = params => { return Axios.post(`${BASE_PATH}/api/course/courselist`, params).then(res => res.data) };
//分类下视频
export const infoList = params => { return Axios.post(`${BASE_PATH}/api/course/infolist`, params).then(res => res.data) };
//播放视频
export const videoInfo = params => { return Axios.post(`${BASE_PATH}/api/course/getvideo`, params).then(res => res.data) };
//图片
export const videoPic = params => { return Axios.post(`${BASE_PATH}/api/course/getvideopic`, params).then(res => res.data) };
//授权结果
export const authback = params => { return Axios.post(`${BASE_PATH}/api/user/authback`, params).then(res => res.data); };
