<template>
    <!-- <div class="login-wrap">
    <div class="ms-login wechat">tyty</div>
  </div> -->

    <section>
        <div v-loading.fullscreen.lock="fullscreenLoading" element-loading-text="登陆中..." class=""></div>
    </section>

</template>

<script>
    import { wxloginurl } from "../../api";

    export default {
        data() {
            return {
                sn: this.$route.query.sn,
                fullscreenLoading: true,
                emptyShow: true,
                code: this.$route.query?.code ?? '',
                state: this.$route.query?.state ?? '',
            };
        },
        mounted() {
            // if (!this.code) {
            //     this.getWxUrl();
            // } else {
            //     console.log('d');
            //     this.$router.replace('/404')
            // }
            if (this.sn) {
                localStorage.setItem("sn", this.sn);
            }
            let token = localStorage.getItem("token1") ?? "";
            if (token) {
                //this.$message.error('有token');
                this.$router.replace("/course?sn=" + this.sn);
            } else {
                //this.$message.error('没有token---------');
                this.getWxUrl();
            }



            //console.log(this.isMobile());
        },
        methods: {
            getWxUrl() {
                console.log(this.sn);
                wxloginurl({ params: { sn: this.sn } }).then((res) => {
                    console.log(res);
                    let { code, msg, data } = res;
                    if (code > 0) {
                        location.replace(data);
                    } else {
                        console.log(msg);
                    }
                });
            },
        },
    };
</script>

<style scoped>
</style>